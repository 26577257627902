import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"

import { REQUEST_TYPE } from "@app/api/constants"
import { useAddFavoritePhoto, useCreateFavoritelist } from "@app/api/favorites/mutations"
import { useGetFavoritesList } from "@app/api/favorites/queries"
import { getFavoritesListKey } from "@app/api/favorites/query-keys"
import { useGallery } from "@app/contexts"
import { ADDED_TO_FAVORITES_NOTIFICATION_TYPE } from "@app/features/notification/constants"
import { useNotification } from "@app/features/notification/store/hooks"
import { usePhotoCarouselStore } from "@app/features/photo-carousel/store"
import { useAuthorizationModal, useHidingPhotosAuth, useLimitModal } from "@app/features/user/stores"
import { useAuthorization } from "@app/features/user/stores"
import { useLikedPhoto } from "features/favorites/store/hooks"
import { fetchGallery } from "features/gallery/requests"
import { authUser } from "features/user/utils"

const isValidEmail = (email: string) => {
  const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return pattern.test(email)
}

export const useAuthFavoritePhoto = (galleryId: string) => {
  const queryClient = useQueryClient()
  const router = useRouter()
  const addFavoritePhotoMutation = useAddFavoritePhoto()
  const createFavoriteListMutation = useCreateFavoritelist()
  const { setGallery } = useGallery()
  const { isAuthorizationModalVisible, hideAuthorizationModal } = useAuthorizationModal()
  const { setUserAuthorization } = useAuthorization()
  const { showLimitModal } = useLimitModal()
  const { setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()
  const { $showNotification } = useNotification()
  const { $likedPhoto, $setLikedPhoto } = useLikedPhoto()
  const likeActivePhoto = usePhotoCarouselStore((state) => state.likeActivePhoto)
  const isPhotoCarouselVisible = usePhotoCarouselStore((state) => state.isPhotoCarouselVisible)
  const [isValidationError, setIsValidationError] = useState(false)
  const [loading, setLoading] = useState(false)

  const favoriteListQuery = useGetFavoritesList(galleryId, { query: { enabled: false } })
  const handleChange = () => {
    setIsValidationError(false)
  }

  const handleAddPhotoToFavorites = async (listId: string, photoId: string) => {
    await addFavoritePhotoMutation.mutateAsync({ id: listId, data: { photo: photoId } })
  }

  const handleAddPhotoToFavoritesSuccess = async () => {
    const slug = router.query.slug
    const domain = window?.location.host
    const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })

    if (gallery.isGrantedHidingPhotos) {
      setUserAuthorizationHidingPhotos(true)
    }
    setGallery(gallery)
  }

  const handleSubmitFormSuccess = async (listId: string) => {
    setLoading(false)
    hideAuthorizationModal()

    if ($likedPhoto) {
      $showNotification({
        type: ADDED_TO_FAVORITES_NOTIFICATION_TYPE,
        image: $likedPhoto.imageSrc,
        link: `/favorites-list/${listId}`,
      })
    }

    if (isPhotoCarouselVisible) {
      likeActivePhoto()
    }

    $setLikedPhoto(null)
    queryClient.invalidateQueries({ queryKey: getFavoritesListKey(galleryId), exact: true })
  }

  const handleFavoriteList = async (existingFavoritesList: any) => {
    if (existingFavoritesList.data.data.isFull) {
      showLimitModal()
      setLoading(false)
      return
    }

    if ($likedPhoto) {
      await handleAddPhotoToFavorites(existingFavoritesList.data.data.id, $likedPhoto.photoId)
    }

    await handleAddPhotoToFavoritesSuccess()
    await handleSubmitFormSuccess(existingFavoritesList.data.data.id)
  }

  const handleCreateFavoriteList = async () => {
    const createdFavoriteListResponse = await createFavoriteListMutation.mutateAsync({
      data: {
        gallery: galleryId,
        description: "",
        name: "Избранное",
      },
    })
    // NOTE: на бэке не описана модель возвращаемых данных, необходимо актуализировать api
    const listId = (createdFavoriteListResponse as any).data.id

    if ($likedPhoto) {
      await handleAddPhotoToFavorites(listId, $likedPhoto.photoId)
    }
    await handleAddPhotoToFavoritesSuccess()
    await handleSubmitFormSuccess(listId)
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault()
    const email = e.target.elements.email.value

    if (!isValidEmail(email)) {
      setIsValidationError(true)
      setUserAuthorization(false)
      return
    }

    setLoading(true)
    const authStatus = await authUser(email)

    if (!authStatus) {
      setLoading(false)
      return
    }

    const existingFavoritesList = await favoriteListQuery.refetch()

    if (existingFavoritesList.data.data) {
      await handleFavoriteList(existingFavoritesList)
    } else {
      await handleCreateFavoriteList()
    }

    setUserAuthorization(true)
  }

  const isLoading =
    addFavoritePhotoMutation.isLoading ||
    createFavoriteListMutation.isLoading ||
    favoriteListQuery.isFetching ||
    loading

  return {
    handleSubmitForm,
    isAuthorizationModalVisible,
    hideAuthorizationModal,
    isValidationError,
    handleChange,
    isLoading,
  }
}
