import { useEffect, useMemo } from "react"
import { useI18n } from "next-localization"

import { useGallery } from "@app/contexts"
import { useGlobalState } from "@app/contexts"
import { Header } from "@app/features/gallery/components"
import { Banner } from "@app/features/hero/components"
import { getFormattedDateVariousType } from "@app/features/hero/utils"
import { HidingMediaFilesHead } from "@app/features/hiding-media-files/components"
import { useHidingMediaFilesStore } from "@app/features/hiding-media-files/store"
import { Meta } from "@app/features/meta/components"
import { GALLERY_META_KEY } from "@app/features/meta/constants"
import { getMeta } from "@app/features/meta/utils"
import { PhotoCarousel } from "@app/features/photo-carousel/components"
import { useSetAllPhotos } from "@app/features/photo-carousel/store/hooks"
import { usePhotoCarousel } from "@app/features/photo-carousel/store/hooks"
import { ProductsContainer } from "@app/features/products/components/products-container"
import { ProductsSection } from "@app/features/products/components/products-section"
import { Review } from "@app/features/review/components"
import { useHidingPhotosAuth } from "@app/features/user/stores"
import { Footer } from "@app/ui/footer"
import { Scene } from "@app/ui/pages/gallery/scene"
import { scrollPhotoToView } from "@app/utils"

import { GalleryCover } from "./gallery-cover"

import styles from "./gallery.module.scss"

const Gallery = () => {
  const i18n = useI18n()
  const { gallery } = useGallery()
  const settings = useGlobalState()
  const hidingMediaFilesList = useHidingMediaFilesStore((state) => state.hidingMediaFilesList)
  const setHidingMediaFilesList = useHidingMediaFilesStore((state) => state.setHidingMediaFilesList)
  const $setAllPhotos = useSetAllPhotos()
  const { isUserAuthorizedHidingPhotos, setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()
  const { $isPhotoCarouselVisible, $activePhoto, $startPhoto } = usePhotoCarousel()
  const { locale, isHidingPhotosEnabled, isGrantedHidingPhotos } = settings

  useEffect(() => {
    setUserAuthorizationHidingPhotos(isGrantedHidingPhotos)
  }, [isGrantedHidingPhotos, setUserAuthorizationHidingPhotos])

  useEffect(() => {
    let allMediaFiles = []
    let hidingMediaFiles = []
    gallery.scenes.forEach((scene) => {
      allMediaFiles = [...allMediaFiles, ...scene.mediaFiles]
      if (isHidingPhotosEnabled) {
        hidingMediaFiles = [...hidingMediaFiles, ...scene.mediaFiles.filter((file) => file.isHidden === true)]
      }
    })

    $setAllPhotos(allMediaFiles)
    if (isHidingPhotosEnabled && !!hidingMediaFiles.length) {
      setHidingMediaFilesList(hidingMediaFiles)
    }
  }, [$setAllPhotos, setHidingMediaFilesList, gallery.scenes, isHidingPhotosEnabled])

  const { scenes, hiddenScenes } = useMemo(() => {
    if (!hidingMediaFilesList.length) return { scenes: gallery.scenes, hiddenScenes: null }

    const hidingMediaFilesListId = hidingMediaFilesList.map((hidden) => hidden.id)

    const updatedScenes = gallery.scenes.map((scene) => {
      return {
        ...scene,
        mediaFiles: scene.mediaFiles.filter((file) => !hidingMediaFilesListId.includes(file.id)),
      }
    })

    const hiddenScenes = gallery.scenes.map((scene) => {
      return {
        ...scene,
        mediaFiles: scene.mediaFiles.filter((file) => hidingMediaFilesListId.includes(file.id)),
      }
    })

    return { scenes: updatedScenes, hiddenScenes }
  }, [hidingMediaFilesList, gallery])

  useEffect(() => {
    if (!$isPhotoCarouselVisible && $activePhoto && $activePhoto?.id !== $startPhoto?.id) {
      scrollPhotoToView(`${$activePhoto?.type}-${$activePhoto?.id}`)
    }
  }, [$isPhotoCarouselVisible, $activePhoto, $startPhoto, scrollPhotoToView])

  const isCanBeHidden = isHidingPhotosEnabled && isUserAuthorizedHidingPhotos
  const isHasHidingMediaFile = isCanBeHidden && Boolean(hidingMediaFilesList.length)
  const hasDisplayReview = gallery.isCommentsEnabled && !gallery.isPhotosInProgress
  const isWithoutScenes = gallery.scenes?.length === 1 && gallery.scenes[0]?.name === ""

  return (
    <>
      <Meta {...getMeta({ key: GALLERY_META_KEY, settings, gallery, i18n })} />
      {gallery.deleteAt && <Banner deleteAt={getFormattedDateVariousType(gallery.deleteAt, locale)} />}

      <GalleryCover gallery={gallery} locale={locale} />

      <Header
        scenes={gallery.scenes}
        cover={gallery.cover}
        logo={settings.logo}
        logoAlt={settings.logoAlt}
        photographerName={settings.name}
        galleryId={gallery.id}
        galleryName={gallery.name}
        photographerSite={settings.site}
        isWithoutScenes={isWithoutScenes}
        isDownloadEnabled={gallery.isDownloadEnabled}
        isHasHidingMediaFile={isHasHidingMediaFile}
        isCanBeHidden={isCanBeHidden}
      />
      <>
        {!gallery.scenes.length && <p className={styles["no-photos-message"]}>{i18n.t("galleryPage.noPhotos")}</p>}
        {scenes.map((scene) => {
          if (scene.mediaFiles.length) {
            return (
              <Scene
                key={scene.id}
                galleryId={gallery.id}
                layout={gallery.layout.handle}
                isDownloadEnabled={gallery.isDownloadEnabled}
                isPhotosInProgress={gallery.isPhotosInProgress}
                isPrintEnabled={gallery.isPrintableProductsEnabled}
                isCanBeHidden={isCanBeHidden}
                scene={scene}
              />
            )
          }
        })}

        {isHasHidingMediaFile && (
          <HidingMediaFilesHead anchor="hidden-photos-01" title={i18n.t("common.hiddenPhotos")} />
        )}

        {!!hiddenScenes &&
          hiddenScenes.map((scene) => {
            if (scene.mediaFiles.length) {
              return (
                <Scene
                  key={scene.id}
                  galleryId={gallery.id}
                  layout={gallery.layout.handle}
                  isDownloadEnabled={gallery.isDownloadEnabled}
                  isPhotosInProgress={gallery.isPhotosInProgress}
                  isCanBeHidden={isCanBeHidden}
                  isPrintEnabled={gallery.isPrintableProductsEnabled}
                  className="is-hidden-photos-scene"
                  isHiddenScene={true}
                  scene={scene}
                />
              )
            }
          })}
        {gallery.isPrintableProductsEnabled && (
          <ProductsContainer>
            <ProductsSection onlyAvailable={false} position={"footer"} />
          </ProductsContainer>
        )}
        {hasDisplayReview && <Review />}
      </>
      <Footer />
      <PhotoCarousel isDownloadEnabled={gallery.isDownloadEnabled} isCanBeHidden={isCanBeHidden} />
    </>
  )
}

export { Gallery }
