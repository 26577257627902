import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"

import { REQUEST_TYPE } from "@app/api/constants"
import { useCreateFavoritelist } from "@app/api/favorites/mutations"
import { getFavoritesListKey } from "@app/api/favorites/query-keys"
import { QA_CLASS_NAMES } from "@app/constants"
import { useGallery } from "@app/contexts"
import { ModalBody } from "@app/features/user/components/modal-body"
import { useAuthorization, useAuthorizationModal, useHidingPhotosAuth } from "@app/features/user/stores"
import { fetchGallery } from "features/gallery/requests"
import { useMobileMenu } from "features/gallery/store/hooks"
import { authUser } from "features/user/utils"
import { Modal } from "ui/modal"

const AuthHidePhotoModal = ({ galleryId }) => {
  const queryClient = useQueryClient()
  const createFavoriteListMutation = useCreateFavoritelist()
  const router = useRouter()

  const { setUserAuthorization } = useAuthorization()
  const { isHidingPhotosModalVisible, hideAuthorizationModalHidingPhotos } = useAuthorizationModal()
  const { setGallery } = useGallery()

  const { setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()
  const { $isMobileMenuVisible, $toggleMobileMenuVisibility } = useMobileMenu()

  const [isValidationError, setIsValidationError] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)

  const [isValidationErrorHidingPhotos, setIsValidationErrorHidingPhotos] = useState(false)

  function handleChange() {
    setIsValidationError(false)
    setIsValidationErrorHidingPhotos(false)
  }

  async function handleSubmit(event) {
    event.preventDefault()
    const email = event.target.elements.email.value
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0 -9-]+(?:\.[a-zA-Z0-9-]+)*$/
    const isValidEmail = pattern.test(email)

    if (isSubmiting) {
      return
    }

    if (isValidEmail) {
      setIsSubmiting(true)
      const authStatus = await authUser(email)

      if (!authStatus) {
        setIsSubmiting(false)
        return
      }

      const slug = router.query.slug
      const domain = window.location.host

      // Hiding Photos
      if (authStatus) {
        const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })
        await createFavoriteListMutation.mutateAsync({
          data: {
            gallery: galleryId,
            description: "",
            name: "Избранное",
          },
        })
        // Если открыто модальное окно авторизации Скрытия фото
        if (isHidingPhotosModalVisible) {
          if (!gallery?.isGrantedHidingPhotos) {
            setIsValidationErrorHidingPhotos(true)
          }

          if (gallery?.isGrantedHidingPhotos) {
            if ($isMobileMenuVisible) {
              // на мобайле авторизация в моб меню, след закрываем его после авторизации
              $toggleMobileMenuVisibility()
            }

            hideAuthorizationModalHidingPhotos()
            setUserAuthorizationHidingPhotos(true)
            // Обновляем gallery
          }
        }
        setGallery(gallery)
        setIsSubmiting(false)
        setUserAuthorization(true)
        queryClient.invalidateQueries({ queryKey: getFavoritesListKey(gallery.id), exact: true })
      }
    } else {
      setUserAuthorization(false)
      setIsValidationError(true)
      setIsSubmiting(false)
    }
  }

  const i18n = useI18n()

  return (
    <>
      <Modal
        open={isHidingPhotosModalVisible}
        onClose={hideAuthorizationModalHidingPhotos}
        crossClassName={QA_CLASS_NAMES.favourites.close}
      >
        <ModalBody
          title={i18n.t("hidingPhotosModal.title")}
          description={i18n.t("hidingPhotosModal.description")}
          validationError={i18n.t("hidingPhotosModal.validationError")}
          login={i18n.t("common.login")}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          isSubmitingCS={isSubmiting}
          isValidationErrorCS={isValidationError}
          isValidationErrorHidingPhotosCS={isValidationErrorHidingPhotos}
          badEmailError={i18n.t("hidingPhotosModal.badEmailError")}
        />
      </Modal>
    </>
  )
}

export { AuthHidePhotoModal }
