import { Album, Center, Classic, Invert, Left, Line, Split, Top } from "@app/features/hero/components"
const componentMap = {
  1: Center,
  2: Split,
  3: Left,
  4: Album,
  5: Classic,
  6: Line,
  7: Top,
  8: Invert,
}
const GalleryCover = ({ gallery, locale }) => {
  if (!gallery || !gallery.coverDesign?.id) return null

  const CoverComponent = componentMap[gallery.coverDesign.id] || Center

  return (
    <CoverComponent
      key={gallery.coverDesign.id}
      cover={gallery.cover}
      name={gallery.name}
      date={gallery.date}
      coverPosition={gallery.settings?.coverPosition}
      locale={locale}
    />
  )
}
export { GalleryCover }
