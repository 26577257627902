import { type FC, useState } from "react"
import clsx from "clsx"

import { SceneInterface } from "@app/features/gallery/types"
import { HidingScene } from "@app/features/hiding-media-files/components"

import styles from "./scene-header.module.scss"

type Props = {
  isCanBeHidden: boolean
  isHiddenScene: boolean
  isMobile: boolean
  scene: SceneInterface
}

const SceneHeader: FC<Props> = ({ scene, isCanBeHidden, isHiddenScene, isMobile }) => {
  const [isHover, setIsHover] = useState(false)

  const isShowHidingBtn = isMobile || isHover || isHiddenScene

  return (
    <>
      {scene.name !== "" && (
        <h1
          className={clsx(
            styles["title"],
            !!scene.description && styles["with-description"],
            isCanBeHidden && styles["with-hiding"],
            isShowHidingBtn && styles["is-hover-and-can-hidden"]
          )}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <span className={styles["title-text"]}>{scene.name}</span>
          {isCanBeHidden && (
            <HidingScene
              id={scene.id}
              mediaFiles={scene.mediaFiles}
              isMediaFilesExist={true}
              isHiddenScene={isHiddenScene}
              isAllMediaFilesHidden={scene.isAllMediaFilesHidden}
            />
          )}
        </h1>
      )}
      {!!scene.description && <p className={clsx(styles["description"])}>{scene.description}</p>}
    </>
  )
}

export { SceneHeader }
