import { FC, memo, ReactElement, useCallback, useRef, useState } from "react"
import { IntersectionOptions, useInView } from "react-intersection-observer"
import clsx from "clsx"

import { SIZE } from "@app/constants"
import { IClientAlbumPhoto } from "@app/features/book-editor-v2/@types"
import { isMobileDevice, useLinkImageInView } from "@app/utils"

import { MediaFile } from "../../types"
import { LazyImage } from "../lazy-image"

import styles from "./scene-photo-preview.module.scss"

const TYPE_SIZE = "w"
const INSTERSECTION_OPTIONS: IntersectionOptions = {
  rootMargin: "150px 0px",
}

type RenderOverlayProps = {
  isHovered: boolean
  photo: MediaFile | IClientAlbumPhoto
  srcImage: string
}

const isMediaFile = (photo: unknown): photo is MediaFile => typeof (photo as MediaFile).photos?.[0] !== "undefined"

type Props = {
  isCursorPointer?: boolean
  photo: MediaFile | IClientAlbumPhoto
  onClick?: (mediaFile: MediaFile | IClientAlbumPhoto) => void
  imageProxyHost: string
  imgFormat: string
  renderOverlay?: ({ isHovered, photo, srcImage }: RenderOverlayProps) => ReactElement
}

const ScenePhotoPreviewComponent: FC<Props> = ({
  photo,
  renderOverlay,
  onClick,
  imageProxyHost,
  imgFormat,
  isCursorPointer,
}) => {
  const shouldRenderOverlay = typeof renderOverlay === "function"
  // const { imageProxyHost, imgFormat } = useGlobalState()

  const ref = useRef()
  const [inViewRef, inView] = useInView(INSTERSECTION_OPTIONS)
  const isMobile = isMobileDevice()

  /**
   * NOTE: https://www.npmjs.com/package/react-intersection-observer#how-can-i-assign-multiple-refs-to-a-component
   */
  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const { srcImage, isReadyImage } = useLinkImageInView(
    SIZE.l,
    TYPE_SIZE,
    isMediaFile(photo) ? photo.photos?.[0] : photo.fileKey,
    imgFormat,
    imageProxyHost,
    photo.contentType,
    inView
  )

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleMouseMove = () => {
    setIsHovered(true)
  }

  return (
    <div
      ref={setRefs}
      className={clsx(styles["root"], isCursorPointer && styles["cursor-pointer"])}
      onMouseEnter={() => handleMouseEnter()}
      onMouseMove={() => handleMouseMove()}
      onMouseLeave={() => handleMouseLeave()}
    >
      {isReadyImage && (
        <LazyImage image={srcImage} onClick={shouldRenderOverlay && isMobile ? () => onClick(photo) : undefined} />
      )}
      {shouldRenderOverlay && !isMobile && (
        <div className={styles["overlay-container"]} onClick={() => onClick?.(photo)}>
          {renderOverlay({ isHovered: isHovered, photo, srcImage })}
        </div>
      )}
    </div>
  )
}

const ScenePhotoPreview = memo(ScenePhotoPreviewComponent)

export { ScenePhotoPreview }
