import { useI18n } from "next-localization"

import { QA_CLASS_NAMES } from "@app/constants"
import { ModalBody } from "@app/features/user/components/modal-body"
import { Modal } from "ui/modal"

import { useAuthFavoritePhoto } from "./use-auth-favorite-photo"

const AuthFavoritePhotoModal = ({ galleryId }) => {
  const { t } = useI18n()
  const {
    isAuthorizationModalVisible,
    isValidationError,
    isLoading,
    hideAuthorizationModal,
    handleSubmitForm,
    handleChange,
  } = useAuthFavoritePhoto(galleryId)

  return (
    <>
      <Modal
        open={isAuthorizationModalVisible}
        onClose={hideAuthorizationModal}
        crossClassName={QA_CLASS_NAMES.favourites.close}
      >
        <ModalBody
          title={t("common.favorites")}
          description={t("authModal.description")}
          validationError={t("authModal.validationError")}
          login={t("common.login")}
          handleSubmit={handleSubmitForm}
          handleChange={handleChange}
          isSubmitingCS={isLoading}
          isValidationErrorCS={isValidationError}
          isValidationErrorHidingPhotosCS={undefined}
          badEmailError={undefined}
        />
      </Modal>
    </>
  )
}

export { AuthFavoritePhotoModal }
